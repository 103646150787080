import React, {
  useEffect, useState,useMemo
} from "react";

import DesktopComponent from "../../main";
import { useParams } from "react-router-dom";
import { preload } from 'react-dom';

import {
  canonUrl,

  findSlugPage,
} from "../../../static/urls";

import "./css/tjanst.css";
import "./css/omrade.css";

import { useMediaQuery } from "react-responsive";

import FokusGrid from '../screencomponents/widget/FokusGrid';
import AccordionDangerous from '../screencomponents/widget/accordionDangerous';
import HomeKunder from '../screencomponents/homeKunder';
import Form from '../../components/generalwidgets/form';
import CompanyReviews from '../../components/generalwidgets/slider/companyReviews';
import CanonicalTag from '../../components/canonicalTag';
import { setMeta } from "../screencomponents/widget/metadesc";
import ThreeSteps from "../screencomponents/threesteps";
import QuoteReview from "../screencomponents/quotereview";


const scrollToForm = () => {
  const formElement = document.getElementById("form-id");
  if (formElement) {
    formElement.scrollIntoView({ behavior: "smooth" });

    window.history.replaceState(null, document.title, window.location.pathname);
  }
};

const CTA = (props) => {
  return (
    <div
      className="omrade-cta-container"
    >
      {props.place && <i>Nationell affärsjuridik från Göteborg</i>}
      <div className="omrade-row">
        <button
          onClick={scrollToForm}

          className="omrade-cta-button"
        >
           Boka en kostnadsfri rådgivning
        </button>

        <a
          href="tel:031527100"

          className="omrade-cta-button"
        >
          031-527 100
        </a>
      </div>
      {props.shadow ? (
        <i className="omrade-main-herov3-p">
          Första konsultationen är kostnadsfri
        </i>
      ) : (
        <i className="omrade-main-herov3-p-no-shadow">
          Första konsultationen är kostnadsfri
        </i>
      )}
    </div>
  );
};
const InfoMedBild = ({
  blocks,
  sprak,
}) => {

  if (!blocks) {
    return null; // or some loading spinner
  }
  const renderBlock = (block) => {
    switch (block.type) {
      case "heading":
        return (
          <div className="avtal-info-heading">
            <h1 dangerouslySetInnerHTML={{ __html: block.value }} />
          </div>
        );
        case "paragraph":
            return (
              <div className="avtal-info-paragraph">
                <p style={{fontSize:16}} dangerouslySetInnerHTML={{ __html: block.value }} />
              </div>
            );
      case "cta":
        return (
          <div className="omrade-offer">
            <div className="omrade-offer-main">
              <h2>{block.value.heading}</h2>
              <p
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              ></p>
            </div>
            <div className="omrade-offer-sub">
              <br></br>
              {block.value.subblocks.map((subblock, i) => (
                <div key={i} className="omrade-cta-block">
                  <h2 className="omrade-offer-sub-h2" >{subblock.heading}</h2>
                  <div
                    className="omrade-offer-sub-p"
                    dangerouslySetInnerHTML={{ __html: subblock.paragraph }}
                  />
                  <button
                    onClick={scrollToForm}
                    className="omrade-vemhur-link"
                  >
                    Skicka en förfrågan
                  </button>


                </div>
              ))}
            </div>
          </div>
        );
      case "vem_och_hur":
        return (
          <div className="omrade-vemhur">
            <div className="omrade-offer-main">
              <h2>{block.value.heading}</h2>
              <p
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              ></p>
            </div>
            <div
              className="omrade-vemhur-sub"
            >
              <br></br>
              {block.value.subblocks.map((subblock, i) => (
                <div
                  key={i}
                  className="omrade-vemhur-block"
                >
                  <h2 className="omrade-offer-sub-h2">{subblock.subheading}</h2>
                  <div
                    className="omrade-offer-sub-p"
                    dangerouslySetInnerHTML={{ __html: subblock.subparagraph }}
                  />
                  <button
                    onClick={scrollToForm}
                    className="omrade-vemhur-link"
                  >
                    Låt oss ta en dialog &rarr;
                  </button>
                </div>
              ))}
            </div>
          </div>
        );
        case "our_services":
          const serviceGroups = block.value.servicegroups.map((group, i) => ({
            id: i,
            icon: group.icon, // could be an emoji, SVG path, or icon name
            title: group.title,
            items: group.services,
          }));
        
          return (
            <section className="omrade-our-services-section" key={`our_services-${block.id}`}>
            <h2 className="omrade-our-services-title">{block.value.heading}</h2>
            <div className="omrade-our-services-grid">
              {serviceGroups.map((group) => (
                <div className="omrade-our-services-card" key={group.id}>
                  <div className="omrade-our-services-header">
                    <span className="omrade-our-services-icon">{group.icon}</span>
                    <h3 className="omrade-our-services-card-title">{group.title}</h3>
                  </div>
                  <ul>
                    {group.items.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>
          
          );
        
      case "alla_tjanster":
        const alla_tjanster = block.value.subblocks.map((subblock, i) => ({
          title: subblock.subheading,
        }));
        return (
          <div
            className="omrade-alla-tjanster-123"

          >

            <div className="omrade-alla-tjanster-text-col">
              <h2 dangerouslySetInnerHTML={{ __html: block.value.heading }} />
              <p dangerouslySetInnerHTML={{ __html: block.value.paragraph }} />
            </div>
            <div className="omrade-alla-tjanster-item-container">
              {alla_tjanster.map((subblock, index) => (
                <h3 key={index} className=" omrade-alla-tjanster-item">
                  {subblock.title}
                </h3>
              ))}
            </div>
          </div>
        );
        case "references":
          return    <QuoteReview
          heading="Vad säger våra kunder?"
          paragraph="Example combines legal knowledge with an extraordinary work approach..."
          reviews={sprak.listOfTitledReviews}
        />;
      case "kontakt_processen":
        return <ThreeSteps onclick={scrollToForm}/>; 
      case "image":
        const { heading, paragraf, caption, imageurl, img_left } =
          block.value;
        return (
          <div className={`omrade-info-image-row ${img_left ? "" : "reverse"}`}>
            <div className="omrade-info-image-container">
              <img
                loading="lazy"
                src={`https://backend.lundberglaw.com:443${imageurl}`}
                alt={heading}
                className="omrade-info-image"
              />
              {caption && <p className="omrade-info-caption">{caption}</p>}
            </div>

            <div className="omrade-info-text-container">
              <h2 className="omrade-info-image-heading">{heading}</h2>
              <div
                className="omrade-info-image-paragraph"
                dangerouslySetInnerHTML={{ __html: paragraf }}
              />
            </div>
          </div>
        );

      case "tips":
        return (
          <div className="avtal-info-tips">
            <h2 className="avtal-tips-heading">{block.value.heading}</h2>
            <div
              className="avtal-tips-paragraph"
              dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
            />
            <div className="avtal-tips-subblocks">
              {block.value.subblocks.map((subblock, index) => (
                <div key={index} className="avtal-tips-subblock">
                  <h3 className="avtal-subblock-heading">
                    {subblock.subheading}
                  </h3>
                  <div
                    className="avtal-subblock-paragraph"
                    dangerouslySetInnerHTML={{ __html: subblock.subparagraph }}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case "fordelar":
        return (
          <div className="omrade-advantages-grid">
            <h2 dangerouslySetInnerHTML={{ __html: block.value.heading }} />
            <p dangerouslySetInnerHTML={{ __html: block.value.paragraph }} />
            {block.value.subblocks.map((subblock, index) => (
              <div key={index} className="omrade-advantage-card">
                <h3 className="omrade-advantage-heading">
                  {subblock.subheading}
                </h3>
                <div
                  className="omrade-advantage-paragraph"
                  dangerouslySetInnerHTML={{ __html: subblock.subparagraph }}
                />
              </div>
            ))}
          </div>
        );
      case "fokus_tjanster":
        // Transform subblocks to match Accordion's expected props structure
        const fokus_tjanster = block.value.subblocks.map((subblock, i) => ({
          id: i, // or any unique identifier
          title: subblock.subheading,
          description: subblock.subparagraph,
          imgurl: subblock.imageurl,
        }));

        return (
          <div className="omrade-info-expert-accordion">
            <div className="omrade-expert-accordion-left">
              <h2 className="omrade-expert-accordion-heading">
                {block.value.heading}
              </h2>
              <div
                className="omrade-expert-accordion-paragraph"
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              />
            </div>

            <FokusGrid items={fokus_tjanster} />
            <CTA place={true} />
          </div>
        );
      case "expert_accordion":
        // Transform subblocks to match Accordion's expected props structure
        const expert_accordion_item = block.value.subblocks.map(
          (subblock, i) => ({
            id: i, // or any unique identifier
            fraga: subblock.subheading,
            svar: subblock.subparagraph,
          })
        );

        return (
          <div className="omrade-info-expert-accordion">
            <div className="omrade-expert-accordion-left">
              <h2 className="omrade-expert-accordion-heading">
                {block.value.heading}
              </h2>
              <div
                className="omrade-expert-accordion-paragraph"
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              />
            </div>
            <div className="omrade-expert-accordion-right">
              {/* Pass the transformed data into your Accordion */}
              <AccordionDangerous
                questions={expert_accordion_item}
                col={true}
                cta={true}
              />
            </div>
          </div>
        );

      case "accordion":
        // Transform subblocks to match Accordion's expected props structure
        const questionsData = block.value.subblocks.map((subblock, i) => ({
          id: i, // or any unique identifier
          fraga: subblock.subheading,
          svar: subblock.subparagraph,
        }));

        return (
          <div className="avtal-info-accordion">
            <div className="avtal-accordion-left">
              <h2 className="avtal-accordion-heading">{block.value.heading}</h2>
              <div
                className="avtal-accordion-paragraph"
                dangerouslySetInnerHTML={{ __html: block.value.paragraph }}
              />
            </div>
            <div className="avtal-accordion-right">
              {/* Pass the transformed data into your Accordion */}
              <AccordionDangerous questions={questionsData} />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="omrade-info-med-bild">
      {blocks.map((block, index) => (
        <div
          key={index}
          className={`avtal-info-block avtal-info-block-${block.type}`}
        >
          {renderBlock(block)}
        </div>
      ))}
    </div>
  );
};


const desktopImages = require.context('../../../static/newimgs', false, /\.webp$/);
const mobileImages = require.context('../../../static/newimgs/mobile', false, /\.webp$/);

const getHeroImage = (id, isMobile) => {
  const imageFile = `${id}.webp`;
  try {
    return isMobile
      ? mobileImages(`./${imageFile}`)
      : desktopImages(`./${imageFile}`);
  } catch {
    return isMobile
      ? mobileImages('./gdpr.webp')
      : desktopImages('./gdpr.webp');
  }
};

const Herov5 = (props) => {
  return (
    <section
    className="omrade-herov5-section"
    style={{
      backgroundImage: `url(${props.img})`,
    }}
  >
    <div className="omrade-herov5-overlay">
      <h1 className="omrade-herov5-title">
      {props.data?.herotitle}
      </h1>
      <p className="omrade-herov5-paragraph">
      {props.data?.herotext}
</p>


<div className="omrade-herov5-buttons">
<a to="/kontakt"             onClick={scrollToForm}
className="omrade-herov5-button">Boka Möte</a>
<a to="/tjanster"  href="tel:031527100" className="omrade-herov5-button">



031-527 100
</a>
</div>

    </div>
  </section>



  );
};

function Omrade(props) {
  const { omradeId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 535 });

  const [data, setData] = useState({});
  const [hydrated, setHydrated] = useState(!isMobile); // Skip delay on desktop
  const [bgReady, setBgReady] = useState(false);


  const herobild = useMemo(
    () => getHeroImage(omradeId, isMobile),
    [omradeId, isMobile]
  );

  const canonicalUrl = canonUrl + props.sprak.omradesLista.url + "/" + omradeId;
  const pageurl = findSlugPage + "omraden/" + omradeId;
  const fetchData = async () => {
    try {
      const response = await fetch(pageurl);
      const data = await response.json();
      setData(data);
      setMeta(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    // Preload and preload LCP image manually
    preload(herobild, { as: "image" });

    const img = new Image();
    img.src = herobild;
    img.decoding = "async";
    img.fetchPriority = "high";
    img.onload = () => setBgReady(true);
    if (window.__META_DATA__) {
      setData(window.__META_DATA__);
      delete window.__META_DATA__; // Important: clear it after first load
    } else {
      fetchData(); 
    } 
    if (isMobile) {
      const timeout = setTimeout(() => setHydrated(true), 250);
      return () => clearTimeout(timeout);
    }
  }, [omradeId, isMobile,herobild]); 

  return (
    <DesktopComponent sprak={props.sprak} light={false}>
      <CanonicalTag canonicalUrl={canonicalUrl} />

      <Herov5
        data={data}
        img={bgReady ? herobild : null}
        sprak={props.sprak}
      />

<HomeKunder/>
{hydrated && data.info_med_bild && (
  <InfoMedBild blocks={data.info_med_bild} sprak={props.sprak} />
)}
      <br></br>
      <br></br>
      <br></br>
      <div
        id="form-id"
        className="omrade-form-container"
      >
        <Form sprak={props.sprak} />
      </div>
      <br></br>
    </DesktopComponent>


  );
}

export default Omrade;
